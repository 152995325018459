import React from "react";
import { Link } from "react-router-dom";

import "./Footerbg.css";

const Footerbg = () => {
  return (
    <footer id="footer" className="footer-1">
      <div className="main-footer widgets-dark typo-light">
        <div className="container">
          <div className="row">
            <div className="col-sm ">
              <div className="widget subscribe no-box justify-content-center">
                <h5 className="widget-title">СВЕТОВИТ61 ООД</h5>
                <p>
                  Нашият всеотдаен и изключително опитен счетоводен екип от
                  професионалисти познава в детайли счетоводните практики в
                  България. <br />
                  Това гарантира, че сме в състояние да се погрижим за
                  цялостните счетоводни нужди на нашите клиенти безпроблемно,
                  точно и бързо.
                </p>
              </div>
            </div>

            <div className="col-sm">
              <div
                className="widget no-box"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <h5 className="widget-title">Връзки</h5>
                <ul className="thumbnail-widget">
                  <li>
                    <div className="thumb-content">
                      <Link to="/services">&nbsp;Услуги</Link>
                    </div>
                  </li>
                  <li>
                    <div className="thumb-content">
                      <Link to="/contacts">&nbsp;Контакти</Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <br />
            <br />

            <div className="col-sm">
              <div
                className="widget no-box"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <h5 className="widget-title">Свържете се с нас</h5>

                <p>
                  <a
                    href="tel:+359889460604"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <i className="fas fa-phone"></i> +359 889 460 604
                  </a>
                </p>
                <p>
                  <a
                    href="tel:++359894310130"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <i className="fas fa-phone"></i> +359 894 310 130
                  </a>
                </p>
                <p>
                  <a
                    href="mailto:derbidv@gmail.com"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <i className="far fa-envelope"></i> derbidv@gmail.com
                  </a>
                </p>
                <p>
                  <a
                    href="http://www.google.com/maps/place/42.054747,25.591243"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <i className="fas fa-map-marker-alt"></i> ул."1-ви Май" 46-3
                    , <br />
                    6400 Димитровград
                  </a>
                </p>
                {/* Контакти с икони */}
                <div
                  className=" row justify-content-center"
                  style={{
                    backgroundColor: "#fff",
                    padding: "5px",
                    width: "60%",
                    margin: "0 auto",
                    borderRadius: "30px",
                  }}
                >
                  <div className=" col-xs-3">
                    <a
                      href="https://msng.link/o/?359889460604=vi"
                      target="_blank"
                      style={{ textDecoration: "none", color: "#665CAC" }}
                    >
                      <i className="fab fa-viber fa-2x"></i>
                    </a>
                  </div>
                  <div
                    className=" col-xs-3"
                    style={{ marginLeft: "20px", marginRight: "20px" }}
                  >
                    <a
                      href="https://wa.me/359889460604"
                      target="_blank"
                      style={{ textDecoration: "none", color: "#4FCE5D" }}
                    >
                      <i className="fab fa-whatsapp fa-2x"></i>
                    </a>
                  </div>
                  <div className=" col-xs-3">
                    <a
                      href="https://msng.link/o/?derbidv=sk"
                      target="_blank"
                      style={{ textDecoration: "none", color: " #00aff0" }}
                    >
                      <i className="fab fa-skype fa-2x"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <p>Copyright SVETOVIT61 LTD © 2021. All rights reserved.</p>
                <p style={{ fontSize: "0.5em" }}>H.H./maddealer</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footerbg;
